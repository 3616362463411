import { gql } from '@apollo/client';
import {
  MasterEntityBasicField,
  CompanyDebtsSummary,
  ReceiverDebtsSummary,
  RiskBlacklistFields,
} from '@fingo/lib/graphql/customers/fragment';
import {
  TypeConnectionFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '@fingo/lib/graphql/fragments';
import {
  CreditNoteFields,
  InvoiceAttachableFields,
  PreofferFields,
  InvoiceDteTypeFields,
  InvoiceIdFields,
  InvoiceStatusFields,

} from '@fingo/lib/graphql/invoices/fragments';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';

// eslint-disable-next-line import/prefer-default-export
export const MY_PREOFFERS_COMMERCIAL = gql`
  query myOffersCommercial(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          ... InvoiceAttachableFields
          preofferSelectable @client
          lightningPaymentAuthorized @client
          invoiceStatus @client
          company {
            id
            executiveAssigned {
              ... UserIdentification
            }
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
            currentCommissionRules {
              id
              threshold
              variant
              value
            }
            conservativeTycRequestingPlatform {
              id
              code
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          uniqueDocumentFinanceState {
            id
            comments
            status {
              id
              code
            }
          }
          preoffer {
            ... PreofferFields
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields} 
  ${InvoiceDteTypeFields} 
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${PreofferFields}
  ${CreditNoteFields}
  ${InvoiceAttachableFields}
  ${RiskBlacklistFields}
`;
