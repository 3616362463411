import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { formatGraphQlDatetime } from '@fingo/lib/helpers';
import Mailing from '../../../saas/components/mailing/Mailing';

const ConfirmingMailing = () => (
  <Mailing
    extraFilters={{
      mailParameter_Mail_EmailName: 'confirming_supplier_letter',
      createdAt_Gte: formatGraphQlDatetime(dayjs().subtract(2, 'weeks')),
    }}
  />
);

export default ConfirmingMailing;
