import { useMutation } from '@apollo/client';
import {
  MY_ASSIGNMENTS,
  MY_OFFERS,
  REEXECUTE_INVOICE_ACTION,
} from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import { useCallback } from 'react';
import { MY_PREOFFERS_COMMERCIAL } from '../graphql/invoices-manager/queries';

const useReceed = () => {
  const { addAlert } = useSnackBars();

  const [reexecuteInvoicesOperation, { loading }] = useMutation(
    REEXECUTE_INVOICE_ACTION,
    {
      variables: {
        reexecutionType: 'Receed',
      },
      onCompleted: () => {
        addAlert({
          id: 'invoice-action-completed',
          color: 'success',
          message: 'Recesión Lista',
        });
      },
      refetchQueries: [MY_OFFERS, MY_ASSIGNMENTS, MY_PREOFFERS_COMMERCIAL],
      onError: ({ message }) => addAlert({ id: 'invoice-action-error', color: 'error', message }),
    },
  );

  const receed = useCallback(
    ({ variables, onCompleted }) => reexecuteInvoicesOperation({ variables, onCompleted }),
  );

  return { receed, loading };
};

export default useReceed;
